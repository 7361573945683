export enum Action {
  Add,
  Duplicate,
  Edit,
  ChangePassword,
  Remind,
  Forget,
  Activate,
  Deactivate,
  Delete,
  Restore,
  PermanentlyDelete,
  DeleteDraft,
  EditDraft,
  Publish,
  ViewProfile,
  View,
  Paginate,
  ChangeOrder
}
