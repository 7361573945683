import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CommonMethodsService } from '../../services/common-methods.service';

@Component({
  selector: 'app-clic-form-actions',
  templateUrl: './clic-form-actions.component.html',
  styleUrls: ['./clic-form-actions.component.css']
})
export class ClicFormActionsComponent implements OnInit {
  @Input() isEdit = false;
  @Input() errorFlag = false;
  @Input() isDraft = false;
  @Input() isPublish = false;
  @Input() new = false;
  @Input() form = null;
  @Input() saveDraft = true;
  @Output() takeAction = new EventEmitter();
  constructor() {


  }

  ngOnInit() {
    if (this.form) {
      this.form.controls['active'].setValue(true);
    }
  }

}
