export const client = {
  LOGIN: '/auth/login',
  REFRESH: '/auth/refresh',
  ERROR: '/error',
  FORGOT: '/auth/forgot-password',
  DASHBOARD: '/dashboard',
  PROFILE: '/dashboard/profile',
  EDIT_PROFILE: '/dashboard/edit-profile',
  MEMBERS: '/dashboard/members',
  ADD_MEMBER: '/dashboard/members/add',
  DUPLICATE_MEMBER: '/dashboard/members/duplicate',
  MEMBER_PROFILE: '/dashboard/members/profile',
  SETTINGS: '/dashboard/settings',
  ADD_SETTINGS: '/dashboard/settings/add',
  EDIT_SETTINGS: '/dashboard/settings/edit',
  /**
   * PAGES
   */
  PAGES: '/dashboard/pages',
  ADD_PAGE: '/dashboard/pages/add',
  EDIT_PAGE: '/dashboard/pages/edit',
  EDIT_DRAFT_PAGE: '/dashboard/pages/edit-draft',
  DUPLICATE_PAGE: '/dashboard/pages/duplicate',

  /**
   * CAREER
   */
  CAREER: '/dashboard/career',
  ADD_CAREER: '/dashboard/career/add',
  EDIT_CAREER: '/dashboard/career/edit',
  EDIT_CAREER_DRAFT: '/dashboard/career/edit-draft',
  DUPLICATE_CAREER: '/dashboard/career/duplicate',

  /**
  * NEWS
  */
  NEWS: '/dashboard/news/',
  ADD_NEWS: '/dashboard/news/add',
  EDIT_NEWS: '/dashboard/news/edit',
  EDIT_DRAFT_NEWS: '/dashboard/news/edit-draft',
  DUPLICATE_NEWS: '/dashboard/news/duplicate',

  /**
   * USERS
  */
  USERS: '/dashboard/uac/users',
  USERS_ADD: '/dashboard/uac/users/add',
  USERS_EDIT: '/dashboard/uac/users/edit',
  USERS_EDIT_DRAFT: '/dashboard/uac/users/edit-draft',
  USERS_DUPLICATE: '/dashboard/uac/users/duplicate',
  /**
   * ROLES
  */
  Roles: '/dashboard/uac/roles',
  Roles_add: '/dashboard/uac/roles/add',
  Roles_edit: '/dashboard/uac/roles/edit',
  Roles_edit_draft: '/dashboard/uac/roles/edit-draft',
  Roles_Duplicate: '/dashboard/uac/roles/duplicate',

  /**
   * PROPERTY
   */
  PROPERTY: '/dashboard/property',
  ADD_PROPERTY: '/dashboard/property/add',
  EDIT_PROPERTY: '/dashboard/property/edit',
  EDIT_PROPERTY_DRAFT: '/dashboard/property/edit-draft',
  DUPLICATE_PROPERTY: '/dashboard/property/duplicate',

  /**
 * AMENITY
 */
  AMENITY: '/dashboard/amenity',
  ADD_AMENITY: '/dashboard/amenity/add',
  EDIT_AMENITY: '/dashboard/amenity/edit',
  EDIT_AMENITY_DRAFT: '/dashboard/amenity/edit-draft',
  DUPLICATE_AMENITY: '/dashboard/amenity/duplicate',

  /**
 * AMENITY CATEGORY
 */
  AMENITY_CATEGORY: '/dashboard/amenity-category',
  ADD_AMENITY_CATEGORY: '/dashboard/amenity-category/add',
  EDIT_AMENITY_CATEGORY: '/dashboard/amenity-category/edit',
  EDIT_AMENITY_CATEGORY_DRAFT: '/dashboard/amenity-category/edit-draft',
  DUPLICATE_AMENITY_CATEGORY: '/dashboard/amenity-category/duplicate',

  /**
* PROPERTY_TYPE
*/
  PROPERTY_TYPE: '/dashboard/property-type',
  ADD_PROPERTY_TYPE: '/dashboard/property-type/add',
  EDIT_PROPERTY_TYPE: '/dashboard/property-type/edit',
  EDIT_PROPERTY_TYPE_DRAFT: '/dashboard/property-type/edit-draft',
  DUPLICATE_PROPERTY_TYPE: '/dashboard/property-type/duplicate',

  /**
* PROPERTY_TYPE_CATEGORY
*/
  PROPERTY_TYPE_CATEGORY: '/dashboard/property-type-category',
  ADD_PROPERTY_TYPE_CATEGORY: '/dashboard/property-type-category/add',
  EDIT_PROPERTY_TYPE_CATEGORY: '/dashboard/property-type-category/edit',
  EDIT_PROPERTY_TYPE_CATEGORY_DRAFT: '/dashboard/property-type-category/edit-draft',
  DUPLICATE_PROPERTY_TYPE_CATEGORY: '/dashboard/property-type-category/duplicate',

  /**
   * COMPLETION STATUS
   */
  COMPLETION_STATUS: '/dashboard/completion-status',
  ADD_COMPLETION_STATUS: '/dashboard/completion-status/add',
  EDIT_COMPLETION_STATUS: '/dashboard/completion-status/edit',
  EDIT_COMPLETION_STATUS_DRAFT: '/dashboard/completion-status/edit-draft',
  DUPLICATE_COMPLETION_STATUS: '/dashboard/completion-status/duplicate',

  /**
    * COMPANY
    */
  COMPANY: '/dashboard/company',
  ADD_COMPANY: '/dashboard/company/add',
  EDIT_COMPANY: '/dashboard/company/edit',
  EDIT_COMPANY_DRAFT: '/dashboard/company/edit-draft',
  DUPLICATE_COMPANY: '/dashboard/company/duplicate',


  /**
  * AGENT
  */
  AGENT: '/dashboard/agent',
  ADD_AGENT: '/dashboard/agent/add',
  EDIT_AGENT: '/dashboard/agent/edit',
  EDIT_AGENT_DRAFT: '/dashboard/agent/edit-draft',
  DUPLICATE_AGENT: '/dashboard/agent/duplicate',

  /**
   * PERMISSIONS
  */
  PERMISSIONS: '/dashboard/uac/permissions',

  /**
   * NAVIGATION
  */
  NAVIGATION: '/dashboard/navigation',

  /**
  * COUNTRY
  */
  COUNTRY: '/dashboard/country',
  ADD_COUNTRY: '/dashboard/country/add',
  EDIT_COUNTRY: '/dashboard/country/edit',
  EDIT_COUNTRY_DRAFT: '/dashboard/country/edit-draft',
  DUPLICATE_COUNTRY: '/dashboard/country/duplicate',

  /**
  * COUNTRY
  */
  CITY: '/dashboard/city',
  ADD_CITY: '/dashboard/city/add',
  EDIT_CITY: '/dashboard/city/edit',
  EDIT_CITY_DRAFT: '/dashboard/city/edit-draft',
  DUPLICATE_CITY: '/dashboard/city/duplicate',

  /**
  * AGENT
  */
  REQUEST: '/dashboard/request',
  ADD_REQUEST: '/dashboard/request/add',
  EDIT_REQUEST: '/dashboard/request/edit',
  EDIT_REQUEST_DRAFT: '/dashboard/request/edit-draft',
  DUPLICATE_REQUEST: '/dashboard/request/duplicate',
  VIEW_REQUEST: '/dashboard/request/view',

};
