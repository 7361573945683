import { Component, OnDestroy, OnInit, ViewChild, Input } from '@angular/core';
import { MatSort, MatPaginator } from '@angular/material';
import { SelectionModel } from '@angular/cdk/collections';
import { Subscription } from 'rxjs';
import { TableContentConfig } from '../models/table-content-config';
import { ActionEventsService } from '../action-events.service';
import { Action } from 'src/app/shared/models/Action';
import { DragDropService } from 'src/app/shared/services/drag-drop.service';

@Component({
  selector: 'app-table-content',
  templateUrl: './table-content.component.html',
  styleUrls: ['./table-content.component.css']
})
export class TableContentComponent implements OnInit, OnDestroy {
  @Input() tableContentConfig: TableContentConfig;
  @Input() tableContentData;
  @Input() showPagination = true;
  @Input() showHeaderActions = true;
  @Input() draggable = false;
  @Input() showAddAction = true;
  @Input() attachments = false;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  selection = new SelectionModel<number>(true, []);

  selectedId: number;

  index;

  filteredValue = '';

  displayedColumnsCopy = [];

  subscriptions: Subscription[] = [];

  sourceRow = null;

  destRow = null;

  constructor(private actionEventsService: ActionEventsService, private dragDropService: DragDropService) {
  }

  ngOnInit() {
    this.resetDisplayedColumns();
    this.index = this.tableContentConfig.index;
    this.addActionsSpecialLabelColumns();

    /**
        * subscribe to sort event
        */
    this.subscriptions.push(this.sort.sortChange.subscribe(
      () => {
        const params = this.getParams();
        this.emitAction({ action: Action.Paginate, ids: [] });
      }
    ));
  }

  page(event) {
    this.setParams((event.pageIndex + 1), event.pageSize);
    const params = this.getParams();
    this.emitAction({ action: Action.Paginate, ids: [] });
  }

  applyFilter(value) {
    this.filteredValue = value.trim();
    this.setParams(1);
    const params = this.getParams();
    this.emitAction({ action: Action.Paginate, ids: [] });
  }

  /**
   * selection methods
   */
  isAllSelected() {
    if (!this.tableContentData) { return false; }
    return (this.selection.selected.length === this.tableContentData.data.length && this.tableContentData.data.length > 0);
  }

  selectAll() {
    if (!this.tableContentData) { return false; }
    this.tableContentData.data.forEach(row => this.selection.toggle(row.id));
  }

  clearAll() {
    this.selection.clear();
  }

  toggle(row) {
    this.selection.toggle(row.id);
  }

  masterToggle(ref) {
    if (this.selection.selected.length === 0) {
      this.selectAll();
    } else {
      ref.checked = false;
      this.clearAll();
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  selectColumn(col: string, checkbox) {
    if (!checkbox.checked) {
      this.displayedColumnsCopy = this.displayedColumnsCopy.filter(
        column => column !== col
      );
    } else {
      this.displayedColumnsCopy.push(col);
      this.displayedColumnsCopy = this.tableContentConfig.displayCols.COLS.filter((item) => this.displayedColumnsCopy.includes(item));
      this.addActionsSpecialLabelColumns();
    }

  }

  addActionsSpecialLabelColumns() {
    this.displayedColumnsCopy.unshift(...this.tableContentConfig.specialLabels);
    if (this.showHeaderActions) {
      this.displayedColumnsCopy.unshift('select');
    }
    this.displayedColumnsCopy.push('actions');
  }

  resetDisplayedColumns() {
    this.displayedColumnsCopy = [...this.tableContentConfig.displayCols.DISPLAYED_COLS];
  }

  getParams() {
    const params = {
      keyword: this.filteredValue.trim(),
      pageSize: (this.tableContentData && this.tableContentData.per_page) ? this.tableContentData.per_page : 50,
      page: (this.tableContentData && this.tableContentData.current_page) ? this.tableContentData.current_page : 1,
      tab: this.tableContentConfig.tabType,
      sortCol: this.sort.active || '',
      sortDir: this.sort.direction || ''
    };
    return params;
  }

  setParams(page = null, pageSize = null) {
    if (!page) {
      page = (this.tableContentData && this.tableContentData.current_page) ? this.tableContentData.current_page : 1;
    }
    if (!pageSize) {
      pageSize = (this.tableContentData && this.tableContentData.per_page) ? this.tableContentData.per_page : 50;
    }
    this.tableContentData.current_page = page;
    this.tableContentData.per_page = pageSize;
    if (this.showPagination) {
      this.paginator.pageIndex = (page - 1);
      this.paginator.pageSize = pageSize;
    }
  }
  addNewItem() {
    this.emitAction({ action: Action.Add, ids: [] });
  }

  emitAction({ ids, action }) {
    const params = this.getParams();
    this.clearAll();
    this.actionEventsService.emitOnTableContentAction({ action, ids, params });
  }

  addDropItem(sourcePage, destPage) {
    if (sourcePage.parent_id !== destPage.parent_id || sourcePage.id === destPage.id) {
      return;
    }
    if (this.tableContentData) {
      this.tableContentData.data = this.dragDropService.moveItemsInArray(this.tableContentData.data, sourcePage, destPage);
      this.emitAction({ ids: [sourcePage.id, destPage.sort_order, destPage.id, sourcePage.sort_order], action: Action.ChangeOrder });
    }

    this.destRow = null;
  }
}
