import { BrowserModule } from '@angular/platform-browser';
import { ErrorHandler, NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { Interceptor } from './shared/interceptors/interceptor';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ClientErrorHandler } from './shared/errors/client-error-handler';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from './shared/shared.module';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { AuthModule } from './modules/auth/auth.module';
import { AgmCoreModule } from '@agm/core';
import { NgDragDropModule } from 'ng-drag-drop';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { SpinnerInterceprotService } from './shared/interceptors/spinner-interceprot';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';


export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}


@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    SharedModule,
    AppRoutingModule,
    AuthModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyA5PmefmUHSxICSAawq3mDYlI09Lb2sq9Q'
    }),
    NgDragDropModule.forRoot(),
    ScrollToModule.forRoot()
  ],
  providers: [
    TranslateService,
    { provide: HTTP_INTERCEPTORS, useClass: SpinnerInterceprotService, multi: true, },
    { provide: HTTP_INTERCEPTORS, useClass: Interceptor, multi: true },
    { provide: ErrorHandler, useClass: ClientErrorHandler },
    // { provide: LocationStrategy, useClass: HashLocationStrategy },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
