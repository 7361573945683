export const localURL = 'http://localhost/php/laravel/Backend2/public/api/';
export const serverURL = 'http://api.emiratescp.com/api/';

export const baseURL = serverURL;
export const mediaPath = 'http://api.emiratescp.com/';


export const api = {
  /**
   * General
   */
  mediaPath: mediaPath,
  UPLOAD_PHOTO: baseURL + 'upload-photo',
  UPLOAD_FILE: baseURL + 'upload-file',
  /**
   * Users
   */
  PERMISSIONS: baseURL + 'uac/permissions/index',
  PERMISSIONS_SAVE: baseURL + 'uac/permissions/save',
  LOGIN: baseURL + 'auth/login',
  LOGOUT: baseURL + 'auth/logout',
  ME: baseURL + 'auth/me',
  FORGOT: baseURL + 'auth/forget-password',
  REFRESH: baseURL + 'auth/refresh',
  CHANGE_PASSWORD: baseURL + 'auth/change-password',
  USERS: baseURL + 'uac/users/index',
  USERS_ADD: baseURL + 'uac/users/add',
  USERS_PAGINATE: baseURL + 'uac/users/paginate',
  USERS_UPDATE: baseURL + 'uac/users/update',
  USERS_DELETE: baseURL + 'uac/users/delete',
  USERS_RESTORE: baseURL + 'uac/users/restore',
  USERS_PERMANENTLY_DELETE: baseURL + 'uac/users/destroy',
  USERS_DRAFT_DELETE: baseURL + 'uac/users/delete-draft',
  USERS_DRAFT_ADD: baseURL + 'uac/users/add-draft',
  USERS_DRAFT_UPDATE: baseURL + 'uac/users/update-draft',
  USERS_CHANGE_PASSWORD: baseURL + 'uac/users/change-password',
  USERS_CHANGE_ATTRIBUTE: baseURL + 'uac/users/change-attributes',
  USERS_UPDATE_PROFILE: baseURL + 'auth/update-profile',
  USERS_GET: baseURL + 'uac/users/get',
  RESET_PASSWORD: baseURL + 'auth/reset-password',
  USERS_GET_DRAFT: baseURL + 'uac/users/get-draft',
  /**
   * Roles End Points
   */
  ROLES: baseURL + 'uac/roles/index',
  ROLES_ADD: baseURL + 'uac/roles/add',
  ROLES_PAGINATE: baseURL + 'uac/roles/paginate',
  ROLES_UPDATE: baseURL + 'uac/roles/update',
  ROLES_DELETE: baseURL + 'uac/roles/delete',
  ROLES_RESTORE: baseURL + 'uac/roles/restore',
  ROLES_PERMANENTLY_DELETE: baseURL + 'uac/roles/destroy',
  ROLES_DRAFT_DELETE: baseURL + 'uac/roles/delete-draft',
  ROLES_DRAFT_ADD: baseURL + 'uac/roles/add-draft',
  ROLES_DRAFT_UPDATE: baseURL + 'uac/roles/update-draft',
  ROLES_CHANGE_PASSWORD: baseURL + 'uac/roles/change-password',
  ROLES_UPLOAD: baseURL + 'uac/roles/upload',
  ROLES_CHANGE_ATTRIBUTE: baseURL + 'uac/roles/change-attributes',
  ROLES_GET: baseURL + 'uac/roles/get',
  ROLES_GET_ALL: baseURL + 'uac/roles/get-all',
  ROLES_GET_DRAFT: baseURL + 'uac/roles/get-draft',

  /**
   * Pages End Points
   */
  PAGES: baseURL + 'pages/index',
  PAGES_GET: baseURL + 'pages/get',
  PAGES_GET_DRAFT: baseURL + 'pages/get-draft',
  PAGES_BUILDER: baseURL + 'pages/builder',
  PAGES_ADD: baseURL + 'pages/add',
  PAGES_PAGINATE: baseURL + 'pages/paginate',
  PAGES_UPDATE: baseURL + 'pages/update',
  PAGES_DELETE: baseURL + 'pages/delete',
  PAGES_RESTORE: baseURL + 'pages/restore',
  PAGES_PERMANENTLY_DELETE: baseURL + 'pages/destroy',
  PAGES_DRAFT_DELETE: baseURL + 'pages/delete-draft',
  PAGES_DRAFT_ADD: baseURL + 'pages/add-draft',
  PAGES_CHANGE_PASSWORD: baseURL + 'pages/change-password',
  PAGES_UPLOAD: baseURL + 'pages/upload',
  PAGES_CHANGE_ATTRIBUTE: baseURL + 'pages/change-attributes',
  PAGES_DRAFT_UPDATE: baseURL + 'pages/update-draft',
  PAGES_CHANGE_ORDER: baseURL + 'pages/change-order',
  PAGES_IDENT_GET: baseURL + 'pages/get-by-ident',
  PAGES_IDENT_UPDATE: baseURL + 'pages/update-by-ident',

  /**
   * Careers End Points
   */
  CAREERS: baseURL + 'career/index',
  CAREERS_GET: baseURL + 'career/get',
  CAREERS_GET_DRAFT: baseURL + 'career/get-draft',
  CAREERS_BUILDER: baseURL + 'career/builder',
  CAREERS_ADD: baseURL + 'career/add',
  CAREERS_PAGINATE: baseURL + 'career/paginate',
  CAREERS_UPDATE: baseURL + 'career/update',
  CAREERS_DELETE: baseURL + 'career/delete',
  CAREERS_RESTORE: baseURL + 'career/restore',
  CAREERS_PERMANENTLY_DELETE: baseURL + 'career/destroy',
  CAREERS_DRAFT_DELETE: baseURL + 'career/delete-draft',
  CAREERS_DRAFT_ADD: baseURL + 'career/add-draft',
  CAREERS_CHANGE_PASSWORD: baseURL + 'career/change-password',
  CAREERS_UPLOAD: baseURL + 'career/upload',
  CAREERS_CHANGE_ATTRIBUTE: baseURL + 'career/change-attributes',
  CAREERS_DRAFT_UPDATE: baseURL + 'career/update-draft',
  CAREERS_CHANGE_ORDER: baseURL + 'career/change-order',

  /**
   * Settings
   */
  SETTINGS: baseURL + 'settings/index',
  SETTINGS_BUILDER: baseURL + 'settings/builder',
  SETTINGS_ADD: baseURL + 'settings/add',
  SETTINGS_UPDATE: baseURL + 'settings/update',
  SETTINGS_DELETE: baseURL + 'settings/delete',
  SETTINGS_GET: baseURL + 'settings/get',

  /**
   * News End Points
   */
  NEWS: baseURL + 'news/index',
  NEWS_GET: baseURL + 'news/get',
  NEWS_GET_DRAFT: baseURL + 'news/get-draft',
  NEWS_BUILDER: baseURL + 'news/builder',
  NEWS_ADD: baseURL + 'news/add',
  NEWS_PAGINATE: baseURL + 'news/paginate',
  NEWS_UPDATE: baseURL + 'news/update',
  NEWS_DELETE: baseURL + 'news/delete',
  NEWS_RESTORE: baseURL + 'news/restore',
  NEWS_PERMANENTLY_DELETE: baseURL + 'news/destroy',
  NEWS_DRAFT_DELETE: baseURL + 'news/delete-draft',
  NEWS_DRAFT_ADD: baseURL + 'news/add-draft',
  NEWS_CHANGE_PASSWORD: baseURL + 'news/change-password',
  NEWS_UPLOAD: baseURL + 'news/upload',
  NEWS_CHANGE_ATTRIBUTE: baseURL + 'news/change-attributes',
  NEWS_DRAFT_UPDATE: baseURL + 'news/update-draft',
  NEWS_CHANGE_ORDER: baseURL + 'news/change-order',


  /**
  * Property End Points
  */
  PROPERTIES: baseURL + 'properties/index',
  PROPERTIES_GET: baseURL + 'properties/get',
  PROPERTIES_GET_DRAFT: baseURL + 'properties/get-draft',
  PROPERTIES_BUILDER: baseURL + 'properties/builder',
  PROPERTIES_ADD: baseURL + 'properties/add',
  PROPERTIES_PAGINATE: baseURL + 'properties/paginate',
  PROPERTIES_UPDATE: baseURL + 'properties/update',
  PROPERTIES_DELETE: baseURL + 'properties/delete',
  PROPERTIES_RESTORE: baseURL + 'properties/restore',
  PROPERTIES_PERMANENTLY_DELETE: baseURL + 'properties/destroy',
  PROPERTIES_DRAFT_DELETE: baseURL + 'properties/delete-draft',
  PROPERTIES_DRAFT_ADD: baseURL + 'properties/add-draft',
  PROPERTIES_CHANGE_PASSWORD: baseURL + 'properties/change-password',
  PROPERTIES_UPLOAD: baseURL + 'properties/upload',
  PROPERTIES_CHANGE_ATTRIBUTE: baseURL + 'properties/change-attributes',
  PROPERTIES_DRAFT_UPDATE: baseURL + 'properties/update-draft',
  PROPERTIES_CHANGE_ORDER: baseURL + 'properties/change-order',

  /**
* Property Completion status End Points
*/
  COMPLETION_STATUSES: baseURL + 'completion-statuses/index',
  COMPLETION_STATUSES_GET: baseURL + 'completion-statuses/get',
  COMPLETION_STATUSES_GET_DRAFT: baseURL + 'completion-statuses/get-draft',
  COMPLETION_STATUSES_BUILDER: baseURL + 'completion-statuses/builder',
  COMPLETION_STATUSES_ADD: baseURL + 'completion-statuses/add',
  COMPLETION_STATUSES_PAGINATE: baseURL + 'completion-statuses/paginate',
  COMPLETION_STATUSES_UPDATE: baseURL + 'completion-statuses/update',
  COMPLETION_STATUSES_DELETE: baseURL + 'completion-statuses/delete',
  COMPLETION_STATUSES_RESTORE: baseURL + 'completion-statuses/restore',
  COMPLETION_STATUSES_PERMANENTLY_DELETE: baseURL + 'completion-statuses/destroy',
  COMPLETION_STATUSES_DRAFT_DELETE: baseURL + 'completion-statuses/delete-draft',
  COMPLETION_STATUSES_DRAFT_ADD: baseURL + 'completion-statuses/add-draft',
  COMPLETION_STATUSES_CHANGE_PASSWORD: baseURL + 'completion-statuses/change-password',
  COMPLETION_STATUSES_UPLOAD: baseURL + 'completion-statuses/upload',
  COMPLETION_STATUSES_CHANGE_ATTRIBUTE: baseURL + 'completion-statuses/change-attributes',
  COMPLETION_STATUSES_DRAFT_UPDATE: baseURL + 'completion-statuses/update-draft',
  COMPLETION_STATUSES_CHANGE_ORDER: baseURL + 'completion-statuses/change-order',

  /**
* Amenities End Points
*/
  AMENITIES: baseURL + 'amenities/index',
  AMENITIES_GET: baseURL + 'amenities/get',
  AMENITIES_GET_DRAFT: baseURL + 'amenities/get-draft',
  AMENITIES_BUILDER: baseURL + 'amenities/builder',
  AMENITIES_ADD: baseURL + 'amenities/add',
  AMENITIES_PAGINATE: baseURL + 'amenities/paginate',
  AMENITIES_UPDATE: baseURL + 'amenities/update',
  AMENITIES_DELETE: baseURL + 'amenities/delete',
  AMENITIES_RESTORE: baseURL + 'amenities/restore',
  AMENITIES_PERMANENTLY_DELETE: baseURL + 'amenities/destroy',
  AMENITIES_DRAFT_DELETE: baseURL + 'amenities/delete-draft',
  AMENITIES_DRAFT_ADD: baseURL + 'amenities/add-draft',
  AMENITIES_CHANGE_PASSWORD: baseURL + 'amenities/change-password',
  AMENITIES_UPLOAD: baseURL + 'amenities/upload',
  AMENITIES_CHANGE_ATTRIBUTE: baseURL + 'amenities/change-attributes',
  AMENITIES_DRAFT_UPDATE: baseURL + 'amenities/update-draft',
  AMENITIES_CHANGE_ORDER: baseURL + 'amenities/change-order',

  /**
* Amenities Categories End Points
*/
  AMENITIES_CATEGORIES: baseURL + 'amenities-categories/index',
  AMENITIES_CATEGORIES_GET: baseURL + 'amenities-categories/get',
  AMENITIES_CATEGORIES_GET_DRAFT: baseURL + 'amenities-categories/get-draft',
  AMENITIES_CATEGORIES_BUILDER: baseURL + 'amenities-categories/builder',
  AMENITIES_CATEGORIES_ADD: baseURL + 'amenities-categories/add',
  AMENITIES_CATEGORIES_PAGINATE: baseURL + 'amenities-categories/paginate',
  AMENITIES_CATEGORIES_UPDATE: baseURL + 'amenities-categories/update',
  AMENITIES_CATEGORIES_DELETE: baseURL + 'amenities-categories/delete',
  AMENITIES_CATEGORIES_RESTORE: baseURL + 'amenities-categories/restore',
  AMENITIES_CATEGORIES_PERMANENTLY_DELETE: baseURL + 'amenities-categories/destroy',
  AMENITIES_CATEGORIES_DRAFT_DELETE: baseURL + 'amenities-categories/delete-draft',
  AMENITIES_CATEGORIES_DRAFT_ADD: baseURL + 'amenities-categories/add-draft',
  AMENITIES_CATEGORIES_CHANGE_PASSWORD: baseURL + 'amenities-categories/change-password',
  AMENITIES_CATEGORIES_UPLOAD: baseURL + 'amenities-categories/upload',
  AMENITIES_CATEGORIES_CHANGE_ATTRIBUTE: baseURL + 'amenities-categories/change-attributes',
  AMENITIES_CATEGORIES_DRAFT_UPDATE: baseURL + 'amenities-categories/update-draft',
  AMENITIES_CATEGORIES_CHANGE_ORDER: baseURL + 'amenities-categories/change-order',

  /**
 * Companies End Points
 */
  COMPANIES: baseURL + 'companies/index',
  COMPANIES_GET: baseURL + 'companies/get',
  COMPANIES_GET_DRAFT: baseURL + 'companies/get-draft',
  COMPANIES_BUILDER: baseURL + 'companies/builder',
  COMPANIES_ADD: baseURL + 'companies/add',
  COMPANIES_PAGINATE: baseURL + 'companies/paginate',
  COMPANIES_UPDATE: baseURL + 'companies/update',
  COMPANIES_DELETE: baseURL + 'companies/delete',
  COMPANIES_RESTORE: baseURL + 'companies/restore',
  COMPANIES_PERMANENTLY_DELETE: baseURL + 'companies/destroy',
  COMPANIES_DRAFT_DELETE: baseURL + 'companies/delete-draft',
  COMPANIES_DRAFT_ADD: baseURL + 'companies/add-draft',
  COMPANIES_CHANGE_PASSWORD: baseURL + 'companies/change-password',
  COMPANIES_UPLOAD: baseURL + 'companies/upload',
  COMPANIES_CHANGE_ATTRIBUTE: baseURL + 'companies/change-attributes',
  COMPANIES_DRAFT_UPDATE: baseURL + 'companies/update-draft',
  COMPANIES_CHANGE_ORDER: baseURL + 'companies/change-order',


  /**
* Agents End Points
*/
  AGENTS: baseURL + 'agents/index',
  AGENTS_GET: baseURL + 'agents/get',
  AGENTS_GET_DRAFT: baseURL + 'agents/get-draft',
  AGENTS_BUILDER: baseURL + 'agents/builder',
  AGENTS_ADD: baseURL + 'agents/add',
  AGENTS_PAGINATE: baseURL + 'agents/paginate',
  AGENTS_UPDATE: baseURL + 'agents/update',
  AGENTS_DELETE: baseURL + 'agents/delete',
  AGENTS_RESTORE: baseURL + 'agents/restore',
  AGENTS_PERMANENTLY_DELETE: baseURL + 'agents/destroy',
  AGENTS_DRAFT_DELETE: baseURL + 'agents/delete-draft',
  AGENTS_DRAFT_ADD: baseURL + 'agents/add-draft',
  AGENTS_CHANGE_PASSWORD: baseURL + 'agents/change-password',
  AGENTS_UPLOAD: baseURL + 'agents/upload',
  AGENTS_CHANGE_ATTRIBUTE: baseURL + 'agents/change-attributes',
  AGENTS_DRAFT_UPDATE: baseURL + 'agents/update-draft',
  AGENTS_CHANGE_ORDER: baseURL + 'agents/change-order',

  /**
* Properties Type End Points
*/
  PROPERTIES_TYPES: baseURL + 'properties-types/index',
  PROPERTIES_TYPES_GET: baseURL + 'properties-types/get',
  PROPERTIES_TYPES_GET_DRAFT: baseURL + 'properties-types/get-draft',
  PROPERTIES_TYPES_BUILDER: baseURL + 'properties-types/builder',
  PROPERTIES_TYPES_ADD: baseURL + 'properties-types/add',
  PROPERTIES_TYPES_PAGINATE: baseURL + 'properties-types/paginate',
  PROPERTIES_TYPES_UPDATE: baseURL + 'properties-types/update',
  PROPERTIES_TYPES_DELETE: baseURL + 'properties-types/delete',
  PROPERTIES_TYPES_RESTORE: baseURL + 'properties-types/restore',
  PROPERTIES_TYPES_PERMANENTLY_DELETE: baseURL + 'properties-types/destroy',
  PROPERTIES_TYPES_DRAFT_DELETE: baseURL + 'properties-types/delete-draft',
  PROPERTIES_TYPES_DRAFT_ADD: baseURL + 'properties-types/add-draft',
  PROPERTIES_TYPES_CHANGE_PASSWORD: baseURL + 'properties-types/change-password',
  PROPERTIES_TYPES_UPLOAD: baseURL + 'properties-types/upload',
  PROPERTIES_TYPES_CHANGE_ATTRIBUTE: baseURL + 'properties-types/change-attributes',
  PROPERTIES_TYPES_DRAFT_UPDATE: baseURL + 'properties-types/update-draft',
  PROPERTIES_TYPES_CHANGE_ORDER: baseURL + 'properties-types/change-order',

  /**
* Properties Category Type End Points
*/
  PROPERTIES_TYPES_CATEGORIES: baseURL + 'properties-types-categories/index',
  PROPERTIES_TYPES_CATEGORIES_GET: baseURL + 'properties-types-categories/get',
  PROPERTIES_TYPES_CATEGORIES_GET_DRAFT: baseURL + 'properties-types-categories/get-draft',
  PROPERTIES_TYPES_CATEGORIES_BUILDER: baseURL + 'properties-types-categories/builder',
  PROPERTIES_TYPES_CATEGORIES_ADD: baseURL + 'properties-types-categories/add',
  PROPERTIES_TYPES_CATEGORIES_PAGINATE: baseURL + 'properties-types-categories/paginate',
  PROPERTIES_TYPES_CATEGORIES_UPDATE: baseURL + 'properties-types-categories/update',
  PROPERTIES_TYPES_CATEGORIES_DELETE: baseURL + 'properties-types-categories/delete',
  PROPERTIES_TYPES_CATEGORIES_RESTORE: baseURL + 'properties-types-categories/restore',
  PROPERTIES_TYPES_CATEGORIES_PERMANENTLY_DELETE: baseURL + 'properties-types-categories/destroy',
  PROPERTIES_TYPES_CATEGORIES_DRAFT_DELETE: baseURL + 'properties-types-categories/delete-draft',
  PROPERTIES_TYPES_CATEGORIES_DRAFT_ADD: baseURL + 'properties-types-categories/add-draft',
  PROPERTIES_TYPES_CATEGORIES_CHANGE_PASSWORD: baseURL + 'properties-types-categories/change-password',
  PROPERTIES_TYPES_CATEGORIES_UPLOAD: baseURL + 'properties-types-categories/upload',
  PROPERTIES_TYPES_CATEGORIES_CHANGE_ATTRIBUTE: baseURL + 'properties-types-categories/change-attributes',
  PROPERTIES_TYPES_CATEGORIES_DRAFT_UPDATE: baseURL + 'properties-types-categories/update-draft',
  PROPERTIES_TYPES_CATEGORIES_CHANGE_ORDER: baseURL + 'properties-types-categories/change-order',

  /**
 * Country End Points
 */
  COUNTRY: baseURL + 'countries/index',
  COUNTRY_GET: baseURL + 'countries/get',
  COUNTRY_GET_DRAFT: baseURL + 'countries/get-draft',
  COUNTRY_BUILDER: baseURL + 'countries/builder',
  COUNTRY_ADD: baseURL + 'countries/add',
  COUNTRY_PAGINATE: baseURL + 'countries/paginate',
  COUNTRY_UPDATE: baseURL + 'countries/update',
  COUNTRY_DELETE: baseURL + 'countries/delete',
  COUNTRY_RESTORE: baseURL + 'countries/restore',
  COUNTRY_PERMANENTLY_DELETE: baseURL + 'countries/destroy',
  COUNTRY_DRAFT_DELETE: baseURL + 'countries/delete-draft',
  COUNTRY_DRAFT_ADD: baseURL + 'countries/add-draft',
  COUNTRY_CHANGE_PASSWORD: baseURL + 'countries/change-password',
  COUNTRY_UPLOAD: baseURL + 'countries/upload',
  COUNTRY_CHANGE_ATTRIBUTE: baseURL + 'countries/change-attributes',
  COUNTRY_DRAFT_UPDATE: baseURL + 'countries/update-draft',
  COUNTRY_CHANGE_ORDER: baseURL + 'countries/change-order',


  /**
 * Country End Points
 */
  CITY: baseURL + 'cities/index',
  CITY_GET: baseURL + 'cities/get',
  CITY_GET_DRAFT: baseURL + 'cities/get-draft',
  CITY_BUILDER: baseURL + 'cities/builder',
  CITY_ADD: baseURL + 'cities/add',
  CITY_PAGINATE: baseURL + 'cities/paginate',
  CITY_UPDATE: baseURL + 'cities/update',
  CITY_DELETE: baseURL + 'cities/delete',
  CITY_RESTORE: baseURL + 'cities/restore',
  CITY_PERMANENTLY_DELETE: baseURL + 'cities/destroy',
  CITY_DRAFT_DELETE: baseURL + 'cities/delete-draft',
  CITY_DRAFT_ADD: baseURL + 'cities/add-draft',
  CITY_CHANGE_PASSWORD: baseURL + 'cities/change-password',
  CITY_UPLOAD: baseURL + 'cities/upload',
  CITY_CHANGE_ATTRIBUTE: baseURL + 'cities/change-attributes',
  CITY_DRAFT_UPDATE: baseURL + 'cities/update-draft',
  CITY_CHANGE_ORDER: baseURL + 'cities/change-order',


  /**
  * REQUESTS End Points
  */
  REQUESTS: baseURL + 'requests/index',
  REQUESTS_GET: baseURL + 'requests/get',
  REQUESTS_GET_DRAFT: baseURL + 'requests/get-draft',
  REQUESTS_BUILDER: baseURL + 'requests/builder',
  REQUESTS_ADD: baseURL + 'requests/add',
  REQUESTS_PAGINATE: baseURL + 'requests/paginate',
  REQUESTS_UPDATE: baseURL + 'requests/update',
  REQUESTS_DELETE: baseURL + 'requests/delete',
  REQUESTS_RESTORE: baseURL + 'requests/restore',
  REQUESTS_PERMANENTLY_DELETE: baseURL + 'requests/destroy',
  REQUESTS_DRAFT_DELETE: baseURL + 'requests/delete-draft',
  REQUESTS_DRAFT_ADD: baseURL + 'requests/add-draft',
  REQUESTS_CHANGE_PASSWORD: baseURL + 'requests/change-password',
  REQUESTS_UPLOAD: baseURL + 'requests/upload',
  REQUESTS_CHANGE_ATTRIBUTE: baseURL + 'requests/change-attributes',
  REQUESTS_DRAFT_UPDATE: baseURL + 'requests/update-draft',
  REQUESTS_CHANGE_ORDER: baseURL + 'requests/change-order',

};


// career
// news
// pages
// settings
// UAC
// amenities
