export const tabsConfig = {
  PAGE_SIZE: 50,
  ALL_TAB_INDEX: 0,
  REMIND_TAB_INDEX: 1,
  DRAFT_TAB_INDEX: 2,
  DELETE_TAB_INDEX: 3,
  ALL_TYPE: 'all',
  REMIND_TYPE: 'remind',
  DRAFT_TYPE: 'draft',
  DELETE_TYPE: 'delete',
};

export const tabelsConfig = {
  ROW_WIDTH: 40
};

export const websiteUrl = 'http://api.emiratescp.com/';
